<template>
  <div class="app-default-layout">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'AppDefaultLayout',
};
</script>

<style scoped>

</style>
